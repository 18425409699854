import React, { useState } from "react";
import { GuidePreview } from "@gigauser/common/src/core/types/guide";
import Icon from "../../../ui/Icon/Icon";

import "./GuidesList.css";
import { SearchResult } from "../../../help-center/HelpCenter/HelpCenter";
import Ring from "../../../ui/Loading/Ring/Ring";
import downArrowheadIcon from "../../../assets/svgs/downArrowheadIcon";

import "./GuidesList.css";
import { GigaUserApi } from "@giga-user-fern/api";

type GuidesListProps = {
	searchResults?: SearchResult[]; //To be passed only if there are search results.
	guidePreviews: GuidePreview[];
	onOpen: (x: GuidePreview) => void;
	bordered?: boolean;
	loading?: boolean; //false by default
	showPublishedStatus: boolean; //whether to show published status
};

const GuidesList: React.FC<GuidesListProps> = (props) => {
	const [loadingGuide, setLoadingGuide] = useState<number | null>(null);

	const [viewAll, setViewAll] = useState(false);

	const onOpenGuide = (guide: GuidePreview, index: number) => {
		if (props.loading) return;
		setLoadingGuide(index);
		props.onOpen(guide);
	};

	if (props.loading) {
		var dummyGuidePreview: (id: string) => GuidePreview = (id) => {
			return {
				id: GigaUserApi.Id(id),
				header: {
					name: "Guide name here",
					description: "This is a description",
					published: false,
				},
				language: GigaUserApi.Id("en"),
				sequenceNumber: 1,
				versionPublished: 1,
				versionSaved: 1,
				versionGenerated: 1,
			};
		};

		var guidePreviews: GuidePreview[] = [
			dummyGuidePreview("1"),
			dummyGuidePreview("2"),
			dummyGuidePreview("3"),
		];
	} else {
		if (viewAll) {
			var guidePreviews = props.guidePreviews;
		} else if (props.guidePreviews) {
			var guidePreviews = props.guidePreviews.slice(0, 4);
		} else {
			guidePreviews = [];
		}
	}

	return (
		<div
			className={`gigauser-GuidesList ${props.bordered ? "gigauser-bordered" : ""}`}
		>
			<div
				className={`gigauser-GuidesList-guides ${props.loading ? "gigauser-GuidesList-loading" : "gigauser-GuidesList-notloading"}`}
			>
				{guidePreviews.map((guide: GuidePreview, index) => {
					return (
						<div
							className={`gigauser-GuidePreview 
                            ${props.loading ? "gigauser-GuidesList-loading" : "gigauser-GuidesList-notloading"}
                            ${index === 0 ? "firstRow" : ""} ${
															index === props.guidePreviews.length - 1
																? "lastRow"
																: ""
														}`}
							onClick={() => onOpenGuide(guide, index)}
						>
							<div className="gigauser-guidelist-header">
								<div className={`gigauser-GuideName`}>{guide.header.name}</div>
								<div className="gigauser-GuideDescription">
									{guide.header.description}
								</div>
							</div>
							{props.showPublishedStatus && !props.loading ? (
								<div className={`gigauser-GuideList-published `}>
									<div
										className={`gigauser-published-circle ${guide.header.published ? "gigauser-published" : "gigauser-draft"}`}
									>
										.
									</div>
								</div>
							) : null}
							{loadingGuide == index ? (
								<div className="gigauser-loading-guide">
									<Ring color="brandColor" height={20} />
								</div>
							) : (
								<Icon className="gigauser-Preview-arrow">
									{downArrowheadIcon()}
								</Icon>
							)}
						</div>
					);
				})}
			</div>
			{guidePreviews.length == 0 ? (
				<div className="gigauser-empty-articles">
					No articles in this collection
				</div>
			) : null}
			{props.guidePreviews && props.guidePreviews.length > 4 ? (
				<div
					className="gigauser-viewAll"
					onClick={() => {
						setViewAll(!viewAll);
					}}
				>
					VIEW {viewAll ? "LESS" : "ALL"}
				</div>
			) : null}
		</div>
	);
};
export default GuidesList;
