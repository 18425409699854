import { setStyle } from "@remirror/core";
import { EditorView, NodeView, ProsemirrorNode } from "@remirror/pm";
import logger from "../../../../utils/logger";
import {
	ResizableNodeView,
	ResizableRatioType,
} from "prosemirror-resizable-view";
import { Node } from "@remirror/pm/dist-types/model";

import "./Extensions.css";
import {
	ConvertToGifFunction,
	EditScreenshotFunction,
	InsertGifInNotebookFunction,
	ScreenshotRenderEdits,
} from "../components/EditScreenshot/useEditScreenshot";
import getEditedScreenshot from "./ScreenshotCanvas/ScreenshotCanvas";
import {
	ImageExtensionMetadata,
	NavigateToTimeFunction,
} from "./SpecialImageExtension";
import { useRef } from "react";
import { render } from "@testing-library/react";
import { useChainedCommands } from "@remirror/react";
/**
 * ZoomImageView is a NodeView for image. You can resize the image by
 * dragging the handle over the image.
 */

interface OptionsType {
	imageStore: { [x: string]: string };
	editable: boolean;
	type: "extension" | "platform" | "videoTranscript" | "helpCenter";
	editScreenshot: EditScreenshotFunction;
	convertToGif: ConvertToGifFunction;
	navigateToTime?: NavigateToTimeFunction;
}
export class ResizableZoomableImageView extends ResizableNodeView {
	constructor(
		node: ProsemirrorNode,
		view: EditorView,
		getPos: () => number,
		imageStore: { [x: string]: string },
		editable: boolean,
		type: "extension" | "platform" | "videoTranscript" | "helpCenter",
		editScreenshot: EditScreenshotFunction,
		convertToGif: ConvertToGifFunction,
		navigateToTime?: NavigateToTimeFunction,
	) {
		super({
			node,
			view,
			getPos,
			options: {
				editable,
				imageStore,
				type,
				editScreenshot,
				convertToGif,
				navigateToTime,
			},
		});
	}

	createElement(props: {
		node: Node;
		view: EditorView;
		getPos: () => number;
		options: OptionsType;
	}): HTMLElement {
		const { editable, type, editScreenshot, convertToGif, navigateToTime } =
			props.options;
		const { node, view, getPos } = props;
		let imageStore = props.options.imageStore;
		let zoomedIn = true;
		let zoomedInSource = "";
		let zoomFactor = node.attrs.metadata.zoomFactor || 1;
		let zoomCenter = node.attrs.metadata.zoomCenter || { x: 0, y: 0 };
		let shapes = node.attrs.metadata.shapes || [];
		let crop = node.attrs.metadata.crop || { position: [0, 0], size: [1, 1] };
		// this.inner = document.createElement('img')
		const inner = document.createElement("img");
		inner.crossOrigin = "anonymous";
		// this is for making sure Chrome cache does not serve the request without CORS headers
		// which is what causes the random zoom in not working errors.
		const final = document.createElement("div");
		const insertGifIntoNotebook: InsertGifInNotebookFunction = (
			url,
			s3ObjectId,
			metadata,
		) => {
			//handle case where need to delete gif on editing.

			view.dispatch(
				view.state.tr.insert(
					getPos(),

					view.state.schema.nodes.image.create({
						src: url,
						metadata: {
							...metadata,
							s3ObjectId: s3ObjectId,
							format: "gif",
						},
					}),
				),
			);
		};

		if (node.attrs.metadata.format !== "gif") {
			//New screenshots
			// Now, all screenshots!

			const zoomCheck = () => {
				// logger.debug(aspectRatio, node.attrs.metadata);
				if (!zoomedIn) {
					inner.src = node.attrs.src;
					imageStore[node.attrs.src] = node.attrs.src;
				}
				if (zoomedIn) {
					inner.src = zoomedInSource;
					imageStore[node.attrs.src] = zoomedInSource;
				}
			};

			const onEditScreenshot: (edits: ScreenshotRenderEdits) => void = (
				edits,
			) => {
				zoomFactor = edits.zoomFactor;
				zoomCenter = edits.zoomCenter;
				shapes = edits.shapes;
				crop = edits.crop;
				regetURL(true);
			};

			const forceRenderImage = () => {
				// if (props.options.resetImageStore) {
				//     const command = props.options.resetImageStore();
				//     command();
				// }
			};

			const regetURL = (dispatch?: boolean) => {
				let img = new Image();
				img.crossOrigin = "anonymous";
				// logger.debug(aspectRatio, node.attrs.metadata);

				// create an alternative img url by copying to a canvas
				img.onload = () => {
					zoomedInSource = getEditedScreenshot(img, {
						zoomFactor: zoomFactor,
						zoomCenter: zoomCenter,
						shapes: shapes,
						crop: crop,
					});
					zoomCheck();
					if (dispatch) {
						view.dispatch(
							view.state.tr.setNodeAttribute(getPos(), "metadata", {
								...node.attrs.metadata,
								zoomFactor: zoomFactor,
								zoomCenter: zoomCenter,
								shapes: shapes,
								crop: crop,
							}),
						);
					}
				};
				img.src = node.attrs.src;
			};

			regetURL();
			//logger.debug(aspectRatio, node.attrs.metadata);
			// if (!imageStore[node.attrs.src]) {
			//     console.log(imageStore[node.attrs.src], "Value of imgSrc")
			//     console.log("IMAGE STORE CHECK RESULT: TRUE :)")
			//     regetURL();
			// } else {
			//     console.log("IMAGE STORE CHECK RESULT: FALSE :(")
			// }

			inner.src = imageStore[node.attrs.src] ?? node.attrs.src;
			inner.className += "gigauser-rich-editor-img";
			// inner.alt = "Loading...";
			setStyle(inner, {
				width: "99%",
				// height: renderedHeight + "px",
				cursor: "pointer",
				minWidth: "50px",
				objectFit: "contain", // maintain image's aspect ratio
			});

			setStyle(inner, {
				minWidth: "50px",
				cursor: node.attrs.metadata.zoomCenter ? "pointer" : "default",
				//objectFit: "contain", // maintain image's aspect ratio
			});
			// create an alternative img url by copying to a canvas
			if (!editable) {
				//Not editable here
				// inner.onclick = () => {
				//     zoomedIn = !zoomedIn;
				//     zoomCheck();
				// };

				return inner;
			} else {
				//This is the editable one.
				// regetURL()
				//Not editable here
				var zoomIn = document.createElement("img");
				zoomIn.className = "gigauser-img-zoomIn";
				zoomIn.alt = "Zoom In";
				zoomIn.style.float = "right";
				zoomIn.style.width = "20px";
				zoomIn.style.height = "20px";
				zoomIn.style.borderRadius = "5px";
				zoomIn.style.padding = "3px";
				zoomIn.style.position = "absolute";
				zoomIn.style.bottom = "5px";
				zoomIn.style.right = "7px";
				zoomIn.style.transition = "0.3s";
				zoomIn.style.scale = "0";
				zoomIn.style.backgroundColor = "rgb(153 153 153)";
				zoomIn.src =
					"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%' height='100%' viewBox='0 0 20 20' fill='none'%3E%3Cpath fill='%23ffffff' fill-rule='evenodd' d='M9 17a1 1 0 102 0v-6h6a1 1 0 100-2h-6V3a1 1 0 10-2 0v6H3a1 1 0 000 2h6v6z'/%3E%3C/svg%3E";

				zoomIn.addEventListener("mouseover", (event) => {
					zoomIn.style.backgroundColor = "rgb(44 43 43)";
				});

				zoomIn.addEventListener("mouseleave", (event) => {
					zoomIn.style.backgroundColor = "rgb(153 153 153)";
				});

				//ZoomOut
				var zoomOut = document.createElement("img");
				zoomOut.alt = "Zoom Out";
				zoomOut.className = "gigauser-img-zoomOut";
				zoomOut.style.float = "right";
				zoomOut.style.width = "20px";
				zoomOut.style.height = "20px";
				zoomOut.style.borderRadius = "5px";
				zoomOut.style.padding = "3px";
				zoomOut.style.position = "absolute";
				zoomOut.style.bottom = "5px";
				zoomOut.style.right = "36px";
				zoomOut.style.transition = "0.3s";
				zoomOut.style.scale = "0";
				zoomOut.style.backgroundColor = "rgb(153 153 153)";
				zoomOut.src =
					"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%' height='100%' viewBox='0 0 20 20' fill='none'%3E%3Cpath fill='%23ffffff' fill-rule='evenodd' d='M18 10a1 1 0 01-1 1H3a1 1 0 110-2h14a1 1 0 011 1z'/%3E%3C/svg%3E";

				zoomOut.addEventListener("mouseover", (event) => {
					zoomOut.style.backgroundColor = "rgb(44 43 43)";
				});

				zoomOut.addEventListener("mouseleave", (event) => {
					zoomOut.style.backgroundColor = "rgb(153 153 153)";
				});

				var deleteIcon = document.createElement("img");
				deleteIcon.className = "gigauser-img-icon";
				deleteIcon.alt = "Delete";
				deleteIcon.src =
					"data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 20 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.66666 23.7499C1.66416 24.0784 1.79205 24.3945 2.02227 24.6288C2.25249 24.8631 2.56625 24.9966 2.89473 24.9999H17.1053C17.4337 24.9966 17.7475 24.8631 17.9777 24.6288C18.2079 24.3945 18.3358 24.0784 18.3333 23.7499V7.08325H1.66666V23.7499ZM3.33333 8.74992H16.6667V23.3333H3.33333V8.74992Z' fill='%23ffa69e'/%3E%3Cpath d='M7.08335 10.4167H5.41669V20.8334H7.08335V10.4167Z' fill='%23ffa69e'/%3E%3Cpath d='M10.8334 10.4167H9.16669V20.8334H10.8334V10.4167Z' fill='%23ffa69e'/%3E%3Cpath d='M14.5834 10.4167H12.9167V20.8334H14.5834V10.4167Z' fill='%23ffa69e'/%3E%3Cpath d='M13.75 3.75V1.25C13.75 0.549062 13.2558 0 12.625 0H7.375C6.74417 0 6.25 0.549062 6.25 1.25V3.75H0V5.41667H20V3.75H13.75ZM7.91667 1.66667H12.0833V3.75H7.91667V1.66667Z' fill='%23ffa69e'/%3E%3C/svg%3E%0A";

				var editIcon = document.createElement("img");
				editIcon.className = "gigauser-img-icon";
				editIcon.alt = "Edit";
				editIcon.src =
					"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 24 24' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='m3.99 16.854-1.314 3.504a.75.75 0 0 0 .966.965l3.503-1.314a3 3 0 0 0 1.068-.687L18.36 9.175s-.354-1.061-1.414-2.122c-1.06-1.06-2.122-1.414-2.122-1.414L4.677 15.786a3 3 0 0 0-.687 1.068zm12.249-12.63 1.383-1.383c.248-.248.579-.406.925-.348.487.08 1.232.322 1.934 1.025.703.703.945 1.447 1.025 1.934.058.346-.1.677-.348.925L19.774 7.76s-.353-1.06-1.414-2.12c-1.06-1.062-2.121-1.415-2.121-1.415z' fill='white'/%3E%3C/svg%3E";
				editIcon.onclick = (e) => {
					// console.log("image: ", imageStore[node.attrs.metadata.stepId ?? node.attrs.metadata.stepID] ?? node.attrs.src )
					let metadata = node.attrs.metadata;
					if (!node.attrs.metadata?.naturalDimensions) {
						metadata = {
							...node.attrs.metadata,
							naturalDimensions: { x: inner.width, y: inner.height },
						};
					}

                    editScreenshot(node.attrs.src, metadata, onEditScreenshot);
				};

				var gifConvert = document.createElement("img");
				gifConvert.className = "gigauser-img-icon";
				gifConvert.src =
					"data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools --%3E%3Csvg fill='%23ffffff' width='800px' height='800px' viewBox='0 0 256 256' id='Flat' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M144,72V184a8,8,0,0,1-16,0V72a8,8,0,0,1,16,0Zm84-8H180a8.00039,8.00039,0,0,0-8,8V184a8,8,0,0,0,16,0V136h28a8,8,0,0,0,0-16H188V80h40a8,8,0,0,0,0-16ZM96,120H72a8,8,0,0,0,0,16H88v16a24,24,0,0,1-48,0V104a24.00492,24.00492,0,0,1,47.24512-6.00586,7.99961,7.99961,0,1,0,15.49414-3.98828A40.00446,40.00446,0,0,0,24,104v48a40,40,0,0,0,80,0V128A8.00039,8.00039,0,0,0,96,120Z'/%3E%3C/svg%3E";
				gifConvert.onclick = (e) => {
					let metadata = node.attrs.metadata;
					if (!node.attrs.metadata?.naturalDimensions) {
						metadata = {
							...node.attrs.metadata,
							naturalDimensions: { x: inner.width, y: inner.height },
						};
					}
					convertToGif(metadata, insertGifIntoNotebook);
				};

				var iconsContainer = document.createElement("div");
				iconsContainer.className = "gigauser-img-iconscontainer";

				iconsContainer.appendChild(deleteIcon);
				if (type == "platform") {
					iconsContainer.appendChild(editIcon);
					if (node.attrs.metadata.timestamp)
						iconsContainer.appendChild(gifConvert);
				}

				if (type == "videoTranscript" && navigateToTime) {
					var navigateToTimeButton = document.createElement("img");
					navigateToTimeButton.className = "gigauser-img-icon";
					navigateToTimeButton.title = "Navigate to frame in video";
					navigateToTimeButton.src =
						"data:image/svg+xml,%3Csvg width='20' height='12' viewBox='0 0 20 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.913809 6.38551L17.1217 6.38551L13.3928 10.4549L14.2717 11.4141L19.5012 5.7072L14.2717 0.000345596L13.3928 0.959535L17.1218 5.02898L0.913809 5.02898V6.38551Z' fill='white'/%3E%3C/svg%3E";
					navigateToTimeButton.onclick = (e) => {
						navigateToTime?.(node.attrs.metadata);
					};
					iconsContainer.appendChild(navigateToTimeButton);
				}

				final.appendChild(inner);
				final.appendChild(iconsContainer);
				// final.appendChild(zoomOut);
				// final.appendChild(zoomIn);
				// final.appendChild(deleteIcon)

				var syncMarkerLabel = document.createElement("div");
				syncMarkerLabel.className =
					"gigauser-sync-marker-label syncMarkerLabel";
				syncMarkerLabel.innerText = "Sync marker";

				if (type == "videoTranscript") {
					final.appendChild(syncMarkerLabel);
				}

				zoomIn.onclick = (e) => {
					e.preventDefault();
					zoomFactor += 0.25;
					regetURL(true);
				};
				zoomOut.onclick = (e) => {
					e.preventDefault();
					zoomFactor -= 0.25;
					zoomFactor = Math.max(zoomFactor, 1);
					regetURL(true);
				};

				deleteIcon.onclick = (e) => {
					let outerTr = view.state.tr;
					view.dispatch(outerTr.deleteRange(getPos(), getPos() + 1));
				};

				final.addEventListener("mouseover", (event) => {
					if (type === "platform") {
						zoomIn.style.scale = "1";
						zoomOut.style.scale = "1";
					}
					iconsContainer.style.opacity = "1";
					// syncMarkerInfo.style.opacity = "0.8"
					syncMarkerLabel.innerText =
						"Video shows this frame when the word before is spoken.";

					syncMarkerLabel.className =
						"gigauser-sync-marker-label syncMarkerInfo ";
				});

				final.addEventListener("mouseleave", (event) => {
					if (type === "platform") {
						zoomIn.style.scale = "0";
						zoomOut.style.scale = "0";
					}
					iconsContainer.style.opacity = "0";
					syncMarkerLabel.innerText = "Sync marker";
					syncMarkerLabel.className =
						"gigauser-sync-marker-label syncMarkerLabel";
				});
				return final;
			}
		} else {
			const inner = document.createElement("img");

			inner.setAttribute("src", node.attrs.src);
			inner.className += "gigauser-rich-editor-img";
			var deleteIcon = document.createElement("img");
			deleteIcon.className = "gigauser-img-icon";
			deleteIcon.alt = "Delete";
			deleteIcon.src =
				"data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 20 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.66666 23.7499C1.66416 24.0784 1.79205 24.3945 2.02227 24.6288C2.25249 24.8631 2.56625 24.9966 2.89473 24.9999H17.1053C17.4337 24.9966 17.7475 24.8631 17.9777 24.6288C18.2079 24.3945 18.3358 24.0784 18.3333 23.7499V7.08325H1.66666V23.7499ZM3.33333 8.74992H16.6667V23.3333H3.33333V8.74992Z' fill='%23ffa69e'/%3E%3Cpath d='M7.08335 10.4167H5.41669V20.8334H7.08335V10.4167Z' fill='%23ffa69e'/%3E%3Cpath d='M10.8334 10.4167H9.16669V20.8334H10.8334V10.4167Z' fill='%23ffa69e'/%3E%3Cpath d='M14.5834 10.4167H12.9167V20.8334H14.5834V10.4167Z' fill='%23ffa69e'/%3E%3Cpath d='M13.75 3.75V1.25C13.75 0.549062 13.2558 0 12.625 0H7.375C6.74417 0 6.25 0.549062 6.25 1.25V3.75H0V5.41667H20V3.75H13.75ZM7.91667 1.66667H12.0833V3.75H7.91667V1.66667Z' fill='%23ffa69e'/%3E%3C/svg%3E%0A";
			deleteIcon.onclick = (e) => {
				let outerTr = view.state.tr;
				view.dispatch(outerTr.deleteRange(getPos(), getPos() + 1));
			};

			var editIcon = document.createElement("img");
			editIcon.className = "gigauser-img-icon";
			editIcon.alt = "Edit";
			editIcon.src =
				"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 24 24' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='m3.99 16.854-1.314 3.504a.75.75 0 0 0 .966.965l3.503-1.314a3 3 0 0 0 1.068-.687L18.36 9.175s-.354-1.061-1.414-2.122c-1.06-1.06-2.122-1.414-2.122-1.414L4.677 15.786a3 3 0 0 0-.687 1.068zm12.249-12.63 1.383-1.383c.248-.248.579-.406.925-.348.487.08 1.232.322 1.934 1.025.703.703.945 1.447 1.025 1.934.058.346-.1.677-.348.925L19.774 7.76s-.353-1.06-1.414-2.12c-1.06-1.062-2.121-1.415-2.121-1.415z' fill='white'/%3E%3C/svg%3E";
			editIcon.onclick = (e) => {
				// console.log("image: ", imageStore[node.attrs.metadata.stepId ?? node.attrs.metadata.stepID] ?? node.attrs.src )
				let metadata = node.attrs.metadata;
				if (!node.attrs.metadata?.naturalDimensions) {
					metadata = {
						...node.attrs.metadata,
						naturalDimensions: { x: inner.width, y: inner.height },
					};
				}

				convertToGif(metadata, insertGifIntoNotebook);
				// editScreenshot(node.attrs.src, metadata, onEditScreenshot);
			};

			var iconsContainer = document.createElement("div");
			iconsContainer.className = "gigauser-img-iconscontainer";

			iconsContainer.appendChild(deleteIcon);
			if (node.attrs.metadata?.editable) iconsContainer.appendChild(editIcon);

			setStyle(inner, {
				width: "99%",
				minWidth: "50px",
				objectFit: "contain", // maintain image's aspect ratio
				position: "relative",
			});
			final.addEventListener("mouseover", (event) => {
				iconsContainer.style.opacity = "1";
			});

			final.addEventListener("mouseleave", (event) => {
				iconsContainer.style.opacity = "0";
			});
			final.appendChild(inner);
			final.appendChild(iconsContainer);
			return final;
		}
		// final.appendChild(inner)
	}
}
