import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import type { RootState } from "@gigauser/common/src/redux/store";

export type OverlayComponent = {
	component: React.ReactNode | null;
	heading?:
		| {
				icon: string | React.ReactNode;
				title: string; //In bold
				data?: string; //Not in bold
				hideSeperator?: boolean;
		  }
		| undefined;
} | null;

// Define a type for the slice state
interface OverlayState {
	value: {
		overlay: OverlayComponent;
		disableClose: boolean;
		disableClickoutOverlay: boolean;
	};
}

// Define the initial state using that type
const initialState: OverlayState = {
	value: {
		overlay: null,
		disableClose: false,
		disableClickoutOverlay: false,
	},
};

export const overlaySlice = createSlice({
	name: "overlay",
	// `createSlice` will infer the state type from the `initialState` argument
	initialState,
	reducers: {
		closeOverlay: (state) => {
			state.value.overlay = null;
			state.value.disableClose = false;
			state.value.disableClickoutOverlay = false;
		},
		// Use the PayloadAction type to declare the contents of `action.payload`
		openOverlay: (state, action: PayloadAction<OverlayComponent>) => {
			state.value.overlay = action.payload;
		},
		setDisableCloseOverlay: (state, action: PayloadAction<boolean>) => {
			state.value.disableClose = action.payload;
		},
		setDisableClickoutOverlay: (state, action: PayloadAction<boolean>) => {
			state.value.disableClickoutOverlay = action.payload;
		},
	},
});

export const {
	setDisableClickoutOverlay,
	closeOverlay,
	openOverlay,
	setDisableCloseOverlay,
} = overlaySlice.actions;
// Other code such as selectors can use the imported `RootState` type
export const selectOverlay = (state: RootState) => state.overlay.value;
export default overlaySlice.reducer;
