const arrowIcon = (color = "#ffffff") => {
	return (
		<svg
			style={{ transform: "scale(1.5)" }}
			width="100%"
			height="100%"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M17 12L7 12M17 12L13 16M17 12L13 8"
				stroke={color}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default arrowIcon;
