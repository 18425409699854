import React, {
	useContext,
	useEffect,
	useRef,
	useState,
} from "react";
import "./CanvasPlayer.css";
import { drawBoxShadow, drawRoundedRect } from "../canvasUtils/canvasUtils";

import { calculateMediaDuration } from "../canvasUtils/videoUtils";
import CanvasPlayerContext from "../CanvasPlayerContext";
import { drawIntro, printIntroClipTitle } from "../canvasUtils/canvasIntro";

import { drawOutro } from "../canvasUtils/canvasOutro";
import { Coordinates } from "@giga-user-fern/api/types/api/resources/baseTypes/types";
import { interpolate } from "../video_effects/interpolations";
import orgSetupCanvas from "../orgSetupCanvas";
import {
	Crop,
	ElementEdit,
} from "@giga-user-fern/api/types/api/resources/video";
import {
	setActiveElement,
	setCustomizerPage,
} from "../../../redux/slices/platformUiSlice";
import { useAppDispatch, useAppSelector } from "../../../redux";
import SizeControllerThumb, {
	SizeControllerPos,
} from "../../../components/formats/RichText/components/EditScreenshot/components/SizeController/SizeControllerThumb";
import { UpdateShapeFunction } from "../../../components/formats/RichText/components/EditScreenshot/ScreenshotEditorContext";
import { updateElement } from "../../../redux/slices/guideSlice";
import { GigaUserApi } from "@giga-user-fern/api";
import DragController from "../../../components/formats/RichText/components/EditScreenshot/components/SizeController/DragController";
import { DEFAULT_CROP } from "../videoEditTypes/core";
import {
	canvasPrintText,
	computeTextboxHeight,
	wrapText,
} from "../canvasUtils/elements/canvasTextbox";
import { selectLogos } from "../../../redux/slices/platformDetailsSlice";
import { Spinner } from "@chakra-ui/react";

export type CanvasPlayerProps = {
	//OPTIONAL
	onLoadedVideo?: () => void;
	onError?: () => void;
	onTimeUpdate?: () => void;
	onEnded?: () => void;
};

const INTERPOLATION_METHOD = "easeOutQuartic";

export const ZOOM_TRANSITION_TIME = 1.2;

const CanvasPlayer: React.FC<CanvasPlayerProps> = (props) => {
	const cp = useContext(CanvasPlayerContext);

	const [videoWidth, setVideoWidth] = useState(
		cp?.vidRef?.current?.videoWidth || 0,
	);
	const [videoHeight, setVideoHeight] = useState(
		cp.vidRef?.current?.videoHeight || 0,
	);

	const [currentZoom, setCurrentZoom] = useState<{
		zoomFactor: number;
		e: number;
		f: number;
	} | null>(null);

	const dispatch = useAppDispatch();

	const [metadataLoaded, setMetadataLoaded] = useState(false);

	const currentElements = useRef<ElementEdit[]>([]);

	const [hoverElementId, setHoverElementId] = useState<GigaUserApi.Id | null>(
		null,
	);
	const activeElementId = useAppSelector(
		(state) => state.platformUi.value.activeElement,
	);

	const [isElementNearCenter, _setIsElementNearCenter] = useState({
		vertical: false,
		horizontal: false,
	});

	var videoEdits = cp.videoEdits;

	const logos = useAppSelector(selectLogos);

	var activeElement: ElementEdit | null = null;
	var hoverElement: ElementEdit | null = null;

	if (videoEdits.elements) {
		activeElement =
			videoEdits.elements.find((e) => e.id === activeElementId) || null;
		hoverElement =
			videoEdits.elements.find((e) => e.id === hoverElementId) || null;
	}

	var animationFrameId: number = 0;
	// var ctx:CanvasRenderingContext2D

	const mainVideoBackgroud = new Image();

	const hasIntroClip =
		cp.videoEdits.intro?.visible && cp.videoEdits.intro.type == "video";
	const hasOutroClip =
		cp.videoEdits.outro?.visible && cp.videoEdits.outro.type == "video";

	if (cp.organization) {
		const defaultSrc = `https://clueso-public-assets.s3.ap-south-1.amazonaws.com/${cp.organization.id}/video_assets/Bookend.png`;
		mainVideoBackgroud.src = defaultSrc;

		var standardizeDims =
			videoEdits.intro?.fixDimensions ||
			videoEdits.outro?.fixDimensions ||
			orgSetupCanvas[cp.organization.id]?.standardizeDims;

		if (videoEdits.background?.src) {
			mainVideoBackgroud.src = videoEdits.background.src;
		}
	}

	// const introLogo = new Image()
	// const outroLogo = new Image()
	// if(cp.organization) {

	//     if (videoEdits.intro?.logo){
	//         introLogo.src = logos.find(l => l.id === videoEdits.intro?.logo)?.src || ""
	//     }
	//     if (videoEdits.outro?.logo){
	//         outroLogo.src = logos.find(l => l.id === videoEdits.outro?.logo)?.src || ""
	//     }
	// }

	const vidRef = cp.vidRef;
	const canvasRef = cp.canvasRef;
	const introVidRef = cp.introVidRef;
	const outroVidRef = cp.outroVidRef;
	const introImgRef = cp.introImgRef;
	const outroImgRef = cp.outroImgRef;

	if (!vidRef) throw new Error("Somehow missing vidRef in CanvasPlayer");

	//#region CANVAS DIMENSIONS

	var videoRenderWidth = useRef(videoWidth);
	var videoRenderHeight = useRef(videoHeight);
	var paddingFactor = 0;

	if (!cp.videoEdits.background?.visible) {
		paddingFactor = 0;
	} else {
		if (cp.videoEdits.background.padding) {
			paddingFactor = cp.videoEdits.background.padding / 100;
		} else {
			paddingFactor = 0;
		}
	}

	const [canvasWidth, setCanvasWidth] = useState(
		videoWidth + paddingFactor * videoWidth,
	);
	const [canvasHeight, setCanvasHeight] = useState(
		videoHeight + paddingFactor * videoWidth,
	);

	if (standardizeDims) {
		// First step: Recompute width and height for the video based on fixed dimensions

		//Now, given a paddingFactor, we know the 'ideal' size of video
		const idealWidth = canvasWidth - paddingFactor * canvasWidth;
		const idealHeight = canvasHeight - paddingFactor * canvasHeight;

		const idealAspectRatio = idealWidth / idealHeight;
		const actualAspectRatio = videoWidth / videoHeight;

		if (actualAspectRatio > idealAspectRatio) {
			videoRenderWidth.current = Math.floor(idealWidth);
			videoRenderHeight.current = Math.floor(idealWidth / actualAspectRatio);
		} else {
			videoRenderHeight.current = Math.floor(idealHeight);
			videoRenderWidth.current = Math.floor(idealHeight * actualAspectRatio);
		}

		videoRenderHeight.current += videoRenderHeight.current % 2;
		videoRenderWidth.current += videoRenderWidth.current % 2;
	} else {
		videoRenderWidth.current = videoWidth;
		videoRenderHeight.current = videoHeight;
	}

	//#endregion

	const initCanvasDims = () => {
		let _canvasWidth = 1920;
		let _canvasHeight = 1080;

		if (videoEdits.intro?.fixDimensions && videoEdits.intro.visible) {
			_canvasWidth = videoEdits.intro?.naturalWidth || 1920;
			_canvasHeight = videoEdits.intro?.naturalHeight || 1080;
		} else if (videoEdits.outro?.fixDimensions && videoEdits.outro.visible) {
			_canvasWidth = videoEdits.outro?.naturalWidth || 1920;
			_canvasHeight = videoEdits.outro?.naturalHeight || 1080;
		} else {
			var _videoWidth = videoWidth;
			var _videoHeight = videoHeight;

			if (_videoWidth && _videoHeight) {
				_canvasWidth = _videoWidth + paddingFactor * _videoWidth;
				_canvasHeight = _videoHeight + paddingFactor * _videoWidth;
			} else {
				_canvasWidth =
					(cp.vidRef?.current?.videoWidth || 0) * (1 + paddingFactor);
				_canvasHeight =
					(cp.vidRef?.current?.videoHeight || 0) +
					paddingFactor * (cp.vidRef?.current?.videoWidth || 0);
			}
		}

		setCanvasWidth(_canvasWidth);
		setCanvasHeight(_canvasHeight);
	};

	//#region useEffects

	useEffect(() => {
		initCanvasDims();
	}, [cp.videoEdits, videoWidth, videoHeight]);

	useEffect(() => {
		if (cp.paused) {
			// cancelAnimationFrame(animationFrameId)
			renderCanvas();
		}
	}, [
		cp.currentTime,
		cp.videoEdits,
		// cp.videoEdits, videoWidth, videoHeight,
		canvasHeight,
		canvasWidth,
	]);

	useEffect(() => {
		setVideoDims();
	}, [cp.videoEdits.crop]);

	useEffect(() => {
		if (vidRef.current) {
			calculateMediaDuration(vidRef.current).then((duration) => {
				if (props.onLoadedVideo) {
					props.onLoadedVideo();
				}
			});
		}
	}, [vidRef]);

	useEffect(() => {
		//This is because when background visibility (canvas width) is changed,
		//The width of text is calculated wrong for some reason.
		renderCanvas();
	}, [cp.videoEdits.background?.visible]);

	useEffect(() => {
		if (!cp.paused) startRendering();
	}, [cp.paused]);

	useEffect(() => {
		const vidRef = cp.vidRef?.current;
		if (!cp.loading && vidRef) {
			// DONT REMOVE THIS CODE! DOUBLE TEXT WILL COME OTHERWISE
			// Check if the fonts are loaded, and if not, wait for them
			if ((document as any).fonts) {
				(document as any).fonts.ready.then((res: any) => {
					// Fonts are loaded, so now we can render the canvas
					renderCanvas();
				});
			} else {
				// The Font Loading API isn't supported, so just render (this is a fallback)
				renderCanvas();
			}
		}
	}, [cp.loading]);

	//#endregion useEffects

	const setVideoDims = () => {
		if (vidRef.current) {
			var videoWidth = vidRef.current.videoWidth;
			var videoHeight = vidRef.current.videoHeight;
			const crop = cp.videoEdits.crop;

			if (crop) {
				videoWidth = videoWidth * crop.size[0];
				videoHeight = videoHeight * crop.size[1];
			}

			setVideoWidth(videoWidth);
			setVideoHeight(videoHeight);

			initCanvasDims();

			videoRenderWidth.current = videoWidth;
			videoRenderHeight.current = videoHeight;
		}
	};

	const handleMetadataLoaded = () => {
		if (vidRef.current) {
			setMetadataLoaded(true);
			setVideoDims();
		}
	};

	const startRendering = () => {
		if (animationFrameId === 0) {
			realTimeRender(); // Start rendering when video is played
		}
	};

	const renderCanvas = async (restore?: boolean) => {
		/**
		 * Re-renders the canvas to the correct visual based on the current time stamp.
		 */

		const video = vidRef.current;
		const canvas = canvasRef?.current;

		if (!video || !canvas || !cp.currentTimeRef) {
			console.error("can't render canvas");
			return;
		}

		const ctx = canvasRef.current?.getContext("2d") as CanvasRenderingContext2D;

		const introDuration = cp.videoEdits.intro?.duration;
		const outroDuration = cp.videoEdits.outro?.duration;

		// Clear previous frame
		ctx.clearRect(0, 0, canvas.width, canvas.height);
		// Clear previous zoom, if any
		ctx.resetTransform();

		//check if we should render intro
		if (
			cp.videoEdits.intro &&
			cp.videoEdits.intro.visible &&
			introDuration &&
			cp.currentTimeRef.current < introDuration
		) {
			//Render the intro

			if (hasIntroClip) {
				renderBookendsFrame("intro");

				if (!cp.videoEdits.intro.hideText) {
					printIntroClipTitle(
						ctx,
						cp.currentTimeRef.current,
						cp.videoEdits.intro,
						{
							width: canvas.width,
							height: canvas.height,
							background: cp.outroImgRef?.current || null,
							logo: cp.introLogoRef?.current || null,
							organization: cp.organization,
						},
					);
				}
			} else {
				await drawIntro(ctx, cp.videoEdits.intro, {
					width: canvas.width,
					height: canvas.height,
					background: cp.introImgRef?.current || null,
					logo: cp.introLogoRef?.current || null,
					organization: cp.organization,
				});
			}
		}
		//check if we should render the outro
		else if (
			cp.videoEdits.outro &&
			cp.videoEdits.outro.visible &&
			outroDuration &&
			cp.currentTimeRef.current >= cp.getScreenclipEndTime()
		) {
			if (cp.videoEdits.outro.type == "video") {
				renderBookendsFrame("outro");
			} else
				await drawOutro(ctx, cp.videoEdits.outro, {
					width: canvas.width,
					height: canvas.height,
					organization: cp.organization,
					background: cp.outroImgRef?.current || null,
					logo: cp.outroLogoRef?.current || null,
				});
		} else {
			//Render the video frame
			// Perform zoom
			scaleCanvas();
			//draw background
			ctx.drawImage(mainVideoBackgroud, 0, 0, canvas.width, canvas.height);

			// Draw video at the center of the canvas
			renderFrame();

			//Draw the shape
			drawElement();
		}

		ctx.restore();
	};

	const handleMouseMove = (
		e: React.MouseEvent<HTMLCanvasElement, MouseEvent>,
	) => {
		const canvas = canvasRef?.current;
		if (currentElements.current.length == 0 || !canvas) {
			setHoverElementId(null);
			return;
		}

		const rect = canvas.getBoundingClientRect();
		const x = e.clientX - (rect?.left ?? 0);
		const y = e.clientY - (rect?.top ?? 0);

		const fracCoords = pixelsToFractionalCoords({ x, y });

		for (var element of currentElements.current) {
			const { size, position } = element;
			const x_f = fracCoords.x;
			const y_f = fracCoords.y;

			// const isHovering = x_c > rectX && x_c < rectX + rectWidth && y_c > rectY && y_c < rectY + rectHeight;
			const isHovering =
				x_f > position[0] &&
				x_f < position[0] + size[0] &&
				y_f > position[1] &&
				y_f < position[1] + size[1];
			if (isHovering) {
				setHoverElementId(element.id);
				return;
			}
		}

		setHoverElementId(null);
	};

	const realTimeRender = () => {
		/**
		 * Renders the canvas in real time as the video is playing.
		 * Stops rendering if video is paused.
		 * @note previously called renderVideoToCanvas (this is what its name
		 * still is on ChatGPT in case you look for it)
		 */

		const video = vidRef.current;
		const canvas = canvasRef?.current;

		if (!video || !canvas || !cp.currentTimeRef) return;

		if (video.readyState === 4) {
			renderCanvas();
		}

		// If video is paused, stop rendering
		if (cp.pausedRef?.current) {
			if (animationFrameId) {
				cancelAnimationFrame(animationFrameId);
				animationFrameId = 0; // reset animationFrameId
			}
			return;
		} else {
			animationFrameId = requestAnimationFrame(realTimeRender);
		}
	};

	//#region COORDINATE SYSTEM CONVERTERS

	/**
	 *
	 * COORDINATE SYSTEM CONVERTERS
	 *
	 * fractionalCoords: {x, y} in the range [0,1] representing a fraction of the
	 * total width and height of only the screenclip in the canvas
	 * canvasCoords: {x, y} in the canvas coordinate system
	 * pixels: {x,y} pixels at the DOM level.
	 * pixels and canvas coords are off by just a scale factor
	 *
	 */

	const fractionalCoordsToCanvasCoords: (
		pos: Coordinates,
		ignoreOffset?: boolean,
	) => Coordinates = (pos, ignoreOffset) => {
		var offsetFactor = 1;
		if (ignoreOffset) offsetFactor = 0;

		const canvas = canvasRef?.current;
		const video = vidRef.current;

		if (!canvas || !video) return pos;

		var vw = videoRenderWidth.current;
		var vh = videoRenderHeight.current;

		if (ignoreOffset) {
			// if ignore offset is true, we are working with pure video file
			vw = videoWidth;
			vh = videoHeight;
		}

		const canvas_x = ((canvas.width - vw) * offsetFactor) / 2 + vw * pos.x;
		const canvas_y = ((canvas.height - vh) * offsetFactor) / 2 + vh * pos.y;

		return { x: canvas_x, y: canvas_y };
	};

	const pixelsToFractionalCoords: (
		pos: Coordinates,
		ignoreOffset?: boolean,
	) => Coordinates = (pos, ignoreOffset) => {
		/**
		 * @param ignoreOffset : to be used when the coordinates input are a difference and not absolute
		 */

		var offsetFactor = 1;
		if (ignoreOffset) offsetFactor = 0;

		const canvas = canvasRef?.current;
		const video = vidRef.current;

		if (!canvas || !video) return pos;

		const rect = canvas.getBoundingClientRect();

		const scaleDownFactor_w = canvas.width / rect.width;
		const scaleDownFactor_h = canvas.height / rect.height;

		const canvasPixelWidth = rect.width;
		const videoPixelWidth = videoRenderWidth.current / scaleDownFactor_w;

		const canvasPixelHeight = rect.height;
		const videoPixelHeight = videoRenderHeight.current / scaleDownFactor_h;

		const x_f =
			(pos.x - ((canvasPixelWidth - videoPixelWidth) * offsetFactor) / 2) /
			videoPixelWidth;
		const y_f =
			(pos.y - ((canvasPixelHeight - videoPixelHeight) * offsetFactor) / 2) /
			videoPixelHeight;

		const f = { x: x_f, y: y_f };
		return f;
	};

	const fractionalCoordsToPixels: (pos_f: Coordinates) => Coordinates = (
		pos_f,
	) => {
		const canvas = canvasRef?.current;
		const video = vidRef.current;

		if (!canvas || !video) return pos_f;

		const rect = canvas.getBoundingClientRect();

		const scaleDownFactor_w = canvas.width / rect.width;
		const scaleDownFactor_h = canvas.height / rect.height;

		const canvasPixelWidth = rect.width;
		const videoPixelWidth = videoRenderWidth.current / scaleDownFactor_w;

		const canvasPixelHeight = rect.height;
		const videoPixelHeight = videoRenderHeight.current / scaleDownFactor_h;

		const x_p =
			pos_f.x * videoPixelWidth + (canvasPixelWidth - videoPixelWidth) / 2;
		const y_p =
			pos_f.y * videoPixelHeight + (canvasPixelHeight - videoPixelHeight) / 2;

		return { x: x_p, y: y_p };
	};

	const canvasCoordsToFractionalCoords: (pos_c: Coordinates) => Coordinates = (
		pos_c,
	) => {
		const canvas = canvasRef?.current;
		const video = vidRef.current;

		if (!canvas || !video) return pos_c;

		const x_f =
			(pos_c.x - (canvas.width - videoRenderWidth.current) / 2) /
			videoRenderWidth.current;
		const y_f =
			(pos_c.y - (canvas.height - videoRenderHeight.current) / 2) /
			videoRenderHeight.current;

		return { x: x_f, y: y_f };
	};

	//#endregion

	const drawElement = () => {
		var elementEdits: ElementEdit[] = [];

		if (!videoEdits.elements) return;

		const video = vidRef.current;
		const canvas = canvasRef?.current;
		const ctx = canvasRef?.current?.getContext(
			"2d",
		) as CanvasRenderingContext2D;

		if (!video || !canvas) return;

		const currentTime = video.currentTime;

		for (const ele of videoEdits.elements) {
			if (currentTime >= ele.startTime && currentTime <= ele.endTime) {
				elementEdits.push(ele);
			}
		}

		// Sort the elementEdits array
		elementEdits.sort((a, b) => {
			if (a.geo === "blur" && b.geo !== "blur") {
				return -1;
			}
			if (a.geo !== "blur" && b.geo === "blur") {
				return 1;
			}
			if (a.geo === "text" && b.geo !== "text") {
				return 1;
			}
			if (a.geo !== "text" && b.geo === "text") {
				return -1;
			}
			return 0;
		});

		currentElements.current = elementEdits;

		if (elementEdits.length) {
			for (let ele of elementEdits) {
				var { position, size, geo } = ele;
				const [x, y] = position;
				const [width, height] = size;

				const crop: Crop = cp.videoEdits.crop || DEFAULT_CROP;

				var rect_coords = fractionalCoordsToCanvasCoords({ x, y });

				var rectX = rect_coords.x;
				var rectY = rect_coords.y;

				var rect_coords_img = fractionalCoordsToCanvasCoords(
					{ x: x, y: y },
					true,
				);

				var rectWidth = width * videoRenderWidth.current; ///adj_zf
				var rectHeight = height * videoRenderHeight.current; // / adj_zf;

				ctx.save();

				if (geo === "blur") {
					//Draw the blur

					const tempCanvas = document.createElement("canvas");
					const tempCtx = tempCanvas.getContext("2d");

					const blurRadius = 36;

					if (!tempCtx) return;

					// Set the dimensions of the temp canvas to the size of the rectangle
					tempCanvas.width = rectWidth + 2 * blurRadius;
					tempCanvas.height = rectHeight + 2 * blurRadius;

					if (videoEdits.crop) {
						var sx = crop.position[0] * video.videoWidth; // Source x - start of crop in video coordinates
						var sy = crop.position[1] * video.videoHeight; // Source y - start of crop in video coordinates
						var sWidth = crop.size[0] * video.videoWidth; // Source width - width of crop in video coordinates
						var sHeight = crop.size[1] * video.videoHeight; // Source height - height of crop in video coordinates
					} else {
						var sx = 0; // Source x - start of crop in video coordinates
						var sy = 0; // Source y - start of crop in video coordinates
						var sWidth = video.videoWidth; // Source width - width of crop in video coordinates
						var sHeight = video.videoHeight; // Source height - height of crop in video coordinates
					}

					var pureRectWidth = width * sWidth;
					var pureRectHeight = height * sHeight;

					// Draw the specific area of the original image onto the temp canvas
					tempCtx.drawImage(
						video,
						sx + rect_coords_img.x - blurRadius,
						sy + rect_coords_img.y - blurRadius,
						pureRectWidth + 2 * blurRadius,
						pureRectHeight + 2 * blurRadius,
						0,
						0,
						rectWidth + 2 * blurRadius,
						rectHeight + 2 * blurRadius,
					);

					// Apply the blur filter
					tempCtx.filter = `blur(${blurRadius / 3}px)`; // adjust the blur radius as needed
					tempCtx.drawImage(tempCanvas, 0, 0);

					// Step 4: Draw the blurred area back onto the original canvas
					ctx.drawImage(
						tempCanvas,
						blurRadius,
						blurRadius,
						rectWidth,
						rectHeight,
						rectX,
						rectY,
						rectWidth,
						rectHeight,
					);
				} else if (geo === "rectangle") {
					var stroke_width: number = 0;
					var stroke_color: string = "transparent";
					// var fill_color: string = 'transparent'
					// var fill_opacity: number = 1

					if (ele.shapedata) {
						stroke_width = ele.shapedata.strokeWidth || 0;
						stroke_color = ele.shapedata.strokeColor || "transparent";
					}

					if (stroke_width === 0) {
						ctx.globalAlpha = 1;
						continue;
					}

					ctx.strokeStyle = stroke_color;
					ctx.lineWidth = stroke_width;
					ctx.strokeRect(rectX, rectY, rectWidth, rectHeight);
				} else if (geo === "text") {
					const displayedWidth = canvas.getBoundingClientRect().width;
					const attributeWidth = canvas.width;

					const scalingFactor = attributeWidth / displayedWidth;

					if (ele.textdata) {
						canvasPrintText(
							ctx,
							{
								x: rectX,
								y: rectY,
								w: rectWidth,
								h: rectHeight,
								scalingFactor,
							},
							ele,
							currentTime,
							cp.paused ? false : true, //animate
						);
					}
				}

				// ctx.fillStyle = 'red';
				// ctx.fillRect(rectX, rectY, rectWidth, rectHeight);
				ctx.restore();
			}
		}
	};

	const interpolateZoom: (
		startTime: number,
		endTime: number,
		startZoom: number,
		endZoom: number,
		currentTime: number,
	) => number = (startTime, endTime, startZoom, endZoom, currentTime) => {
		const start = { y: startZoom, x: startTime };
		const end = { y: endZoom, x: endTime };

		return interpolate(start, end, currentTime, INTERPOLATION_METHOD);
	};

	const interpolateCenter = (
		startCenter: Coordinates,
		endCenter: Coordinates,
		startTime: number,
		endTime: number,
		currentTime: number,
	): Coordinates => {
		const startX = { y: startCenter.x, x: startTime };
		const endX = { y: endCenter.x, x: endTime };
		const X = interpolate(startX, endX, currentTime, INTERPOLATION_METHOD);

		const startY = { y: startCenter.y, x: startTime };
		const endY = { y: endCenter.y, x: endTime };
		const Y = interpolate(startY, endY, currentTime, INTERPOLATION_METHOD);

		return { x: X, y: Y };
	};

	const scaleCanvas: () => { zoomFactor: number; zoomCenter?: Coordinates } =
		() => {
			let zoomFactor: number = 1;
			let zoomCenter: Coordinates = { x: 0.5, y: 0.5 };

			let e: number = 0;
			let f: number = 0;

			const video = vidRef.current;
			const canvas = canvasRef?.current;
			const ctx = canvasRef?.current?.getContext(
				"2d",
			) as CanvasRenderingContext2D;

			if (!video || !canvas) return { zoomFactor, zoomCenter };

			var zoomEdit;
			const currentTime = video.currentTime;

			var prevFactor = 1;
			var nextFactor = 1;
			var nextCenter = { x: 0.5, y: 0.5 };

			if (!videoEdits.zooms) return { zoomFactor, zoomCenter };

			const zooms = Array.from(videoEdits.zooms).sort(
				(a, b) => a.startTime - b.startTime,
			);

			for (let i = 0; i < zooms.length; i++) {
				const currentZoom = zooms[i];

				if (
					currentZoom.startTime > currentTime ||
					currentTime > currentZoom.endTime
				) {
					continue;
				} else {
					zoomEdit = currentZoom;

					if (i > 0 && currentZoom.startTime === zooms[i - 1].endTime) {
						prevFactor = zooms[i - 1].zoomFactor;
					}

					if (
						i < zooms.length - 1 &&
						currentZoom.endTime === zooms[i + 1].startTime
					) {
						nextFactor = zooms[i + 1].zoomFactor;
						nextCenter = zooms[i + 1].zoomCenter;
					}

					break;
				}
			}

			if (zoomEdit) {
				var transitionTime = Math.min(
					zoomEdit.transitionTime || ZOOM_TRANSITION_TIME,
					(zoomEdit.endTime - zoomEdit.startTime) / 2,
				);

				//timings
				const zoomInFinishTime = zoomEdit.startTime + transitionTime;
				const zoomOutStartTime = zoomEdit.endTime - transitionTime;

				//zoom
				const currZoom = zoomEdit.zoomFactor;
				const currCenter = zoomEdit.zoomCenter;

				if (
					zoomEdit.startTime < currentTime &&
					currentTime <= zoomInFinishTime
				) {
					//The zoom in phase
					if (prevFactor === 1)
						zoomFactor = interpolateZoom(
							zoomEdit.startTime,
							zoomInFinishTime,
							prevFactor,
							currZoom,
							currentTime,
						);
					else zoomFactor = currZoom;

					zoomCenter = currCenter;
				} else if (
					zoomInFinishTime < currentTime &&
					currentTime <= zoomOutStartTime
				) {
					//The keep zoom constant phase
					zoomFactor = currZoom;
					zoomCenter = currCenter;
				} else if (
					zoomOutStartTime < currentTime &&
					currentTime <= zoomEdit.endTime
				) {
					//The zoom out phase
					zoomFactor = interpolateZoom(
						zoomOutStartTime,
						zoomEdit.endTime,
						currZoom,
						nextFactor,
						currentTime,
					);

					if (nextCenter.x === 0.5 && nextCenter.y === 0.5 && nextFactor === 1)
						zoomCenter = currCenter;
					else
						zoomCenter = interpolateCenter(
							currCenter,
							nextCenter,
							zoomOutStartTime,
							zoomEdit.endTime,
							currentTime,
						);
				}

				const zoom_canvas_coords = fractionalCoordsToCanvasCoords(zoomCenter);

				e = zoom_canvas_coords.x * (1 - zoomFactor);
				f = zoom_canvas_coords.y * (1 - zoomFactor);

				setCurrentZoom({ zoomFactor, e, f });
			} else setCurrentZoom(null);

			// Apply transformations for zoom effect
			ctx.save();

			// Set the transformations using setTransform
			ctx.setTransform(zoomFactor, 0, 0, zoomFactor, e, f);

			return { zoomFactor, zoomCenter };
		};

	const scaleCoordinates: (c: Coordinates) => Coordinates = (c) => {
		// Takes as input canvas coords and returns as output canvas coords
		if (!currentZoom) return c;

		// Apply the zoom effect
		const [X, Y] = [c.x, c.y];
		const [A, B, C, D, E, F] = [
			currentZoom.zoomFactor,
			0,
			0,
			currentZoom.zoomFactor,
			currentZoom.e,
			currentZoom.f,
		];

		const transformedX = A * X + C * Y + E;
		const transformedY = B * X + D * Y + F;

		const transformedPoint = [transformedX, transformedY];

		return { x: transformedPoint[0], y: transformedPoint[1] };
	};

	const renderFrame = () => {
		const video = vidRef.current;
		const canvas = canvasRef?.current;
		const ctx = canvas?.getContext("2d") as CanvasRenderingContext2D;

		if (!video || !canvas || !cp.currentTimeRef) {
			console.error("can't render canvas!");
			return;
		}

		var source: HTMLVideoElement = video;

		if (cp.clips && cp.clips.length) {
			const videoTime = cp.timelineToVideoTime(cp.currentTimeRef.current);
			const sourceId = videoTime.sourceId;
			const sourceClip = cp.sources?.find((s) => s.id === sourceId);

			if (sourceClip?.ref.current) source = sourceClip.ref.current;
		}

		const bgEdits = cp.videoEdits.background;

		// Clip rounded rectangle path for the video
		ctx.save();
		ctx.beginPath();

		// ctx.clearRect(0, 0, canvas.width, canvas.height);

		const borderRadius =
			(videoHeight * (cp.videoEdits.background?.borderRadius || 0)) / 100;

		var x_pos = (canvas.width - videoRenderWidth.current) * 0.5;
		var y_pos = (canvas.height - videoRenderHeight.current) * 0.5;

		if (bgEdits?.shadow)
			drawBoxShadow(
				ctx,
				x_pos,
				y_pos,
				videoRenderWidth.current,
				videoRenderHeight.current,
				bgEdits?.shadow,
				borderRadius,
			);
		drawRoundedRect(
			ctx,
			x_pos,
			y_pos,
			videoRenderWidth.current,
			videoRenderHeight.current,
			borderRadius,
		);

		ctx.clip();

		var vidWidth = videoRenderWidth.current;
		var vidHeight = videoRenderHeight.current;

		//compute crop
		if (videoEdits.crop) {
			const crop = videoEdits.crop;
			const sx = crop.position[0] * video.videoWidth; // Source x - start of crop in video coordinates
			const sy = crop.position[1] * video.videoHeight; // Source y - start of crop in video coordinates
			const sWidth = crop.size[0] * video.videoWidth; // Source width - width of crop in video coordinates
			const sHeight = crop.size[1] * video.videoHeight; // Source height - height of crop in video coordinates

			ctx.drawImage(
				source,
				sx,
				sy,
				sWidth,
				sHeight,
				x_pos,
				y_pos,
				videoRenderWidth.current,
				videoRenderHeight.current,
			);
		} else {
			ctx.drawImage(source, x_pos, y_pos, vidWidth, vidHeight);
		}

		//
		// Draw the cropped video frame to the canvas
	};

	const renderBookendsFrame = (slide: "intro" | "outro") => {
		var bookendsVideo;

		if (slide == "intro") {
			if (!introVidRef) return;
			bookendsVideo = introVidRef.current;
		} else {
			if (!outroVidRef) return;
			bookendsVideo = outroVidRef.current;
		}

		if (!bookendsVideo) return;

		const canvas = canvasRef?.current;
		const ctx = canvas?.getContext("2d") as CanvasRenderingContext2D;

		if (!bookendsVideo || !canvas) return;

		// Clip rounded rectangle path for the video
		ctx.save();
		ctx.beginPath();

		var vidWidth =
			slide == "intro"
				? cp.videoEdits.intro?.naturalWidth
				: cp.videoEdits.outro?.naturalWidth;
		var vidHeight =
			slide == "intro"
				? cp.videoEdits.intro?.naturalHeight
				: cp.videoEdits.outro?.naturalHeight;

		vidWidth = vidWidth || 1920;
		vidHeight = vidHeight || 1080;

		var x_pos = (canvas.width - vidWidth) * 0.5;
		var y_pos = (canvas.height - vidHeight) * 0.5;

		ctx.drawImage(bookendsVideo, 0, 0, canvas.width, canvas.height);
	};

	const updateElementEdit: UpdateShapeFunction = (id, updatedProperties) => {
		if (!videoEdits.elements) return;

		const { size } = updatedProperties;
		const currentElement = videoEdits.elements.find((e) => e.id == id);
		if (!currentElement) return;

		var newElement: ElementEdit = { ...currentElement };

		Object.entries(updatedProperties).forEach(([key, value]) => {
			return ((newElement as any)[key] = value);
		});

		//if the element is a textbox, we need to recalculate the lines
		const video = vidRef.current;
		const canvas = canvasRef?.current;

		if (!video || !canvas || !cp.currentTimeRef) return;

		if (currentElement.geo === "text" && canvas && currentElement.textdata) {
			if (size) {
				const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;

				//the size is updated.
				if (size[1] == currentElement.size[1]) {
					//only width is changed
					const canvasDims = fractionalCoordsToCanvasCoords({
						x: size[0],
						y: size[1],
					});
					const lines = wrapText(
						ctx,
						currentElement.textdata?.text,
						canvasDims.x,
						currentElement.textdata.fontSize,
					);

					const newHeight_f = computeTextboxHeight(
						canvas,
						lines,
						currentElement.textdata.fontSize,
					);

					newElement = {
						...newElement,
						size: [newElement.size[0], newHeight_f],
						textdata: {
							...currentElement.textdata,
							lines: lines,
						},
					};
				} else {
					//width and height are both changing

					const scaleUp = size[1] / currentElement.size[1];

					newElement = {
						...newElement,
						textdata: {
							...currentElement.textdata,
							fontSize: currentElement.textdata.fontSize * scaleUp,
						},
					};
				}
			}
		}

		dispatch(updateElement(newElement));
	};

	useEffect(() => {
		// ctx = canvasRef.current?.getContext('2d') as CanvasRenderingContext2D;

		if (vidRef.current) {
			vidRef.current.addEventListener("loadedmetadata", handleMetadataLoaded);
			//   vidRef.current.addEventListener('play', startRendering)
		}

		return () => {
			cancelAnimationFrame(animationFrameId);
			if (vidRef.current) {
				vidRef.current.removeEventListener(
					"loadedmetadata",
					handleMetadataLoaded,
				);
				// vidRef.current.removeEventListener('play', startRendering)
			}
		};
	}, [videoWidth, videoHeight, canvasRef?.current, videoEdits]);

	const hoverDivStyle = {};

	var hoverDivTopLeft = { x: 0, y: 0 };
	var hoverDivBotRight = { x: 0, y: 0 };
	var hoverDivWidth = 0;
	var hoverDivHeight = 0;

	const primeElement = activeElement || hoverElement;

	if (primeElement) {
		hoverDivTopLeft = fractionalCoordsToCanvasCoords({
			x: primeElement.position[0],
			y: primeElement.position[1],
		});
		hoverDivBotRight = fractionalCoordsToCanvasCoords({
			x: primeElement.position[0] + primeElement.size[0],
			y: primeElement.position[1] + primeElement.size[1],
		});

		hoverDivTopLeft = scaleCoordinates(hoverDivTopLeft);
		hoverDivBotRight = scaleCoordinates(hoverDivBotRight);

		hoverDivTopLeft = fractionalCoordsToPixels(
			canvasCoordsToFractionalCoords(hoverDivTopLeft),
		);
		hoverDivBotRight = fractionalCoordsToPixels(
			canvasCoordsToFractionalCoords(hoverDivBotRight),
		);

		hoverDivWidth = hoverDivBotRight.x - hoverDivTopLeft.x;
		hoverDivHeight = hoverDivBotRight.y - hoverDivTopLeft.y;
	}

	var sizeControls: SizeControllerPos[] = ["tl", "tr", "br", "bl", "l", "r"];

	if (!(activeElement?.geo == "text")) {
		sizeControls = [...sizeControls, "t", "b"];
	}

	const showElementEditor: (element: ElementEdit) => boolean = (element) => {
		var showElementEditor = false;

		if (
			cp.currentTimeRef &&
			element.startTime <= cp.getUnadjustedTime(cp.currentTimeRef.current) &&
			element.endTime >= cp.getUnadjustedTime(cp.currentTimeRef.current) &&
			cp.paused
		) {
			showElementEditor = true;
		}

		return showElementEditor;
	};

	const setIsElementNearCenter: () => void = () => {
		if (activeElement?.geo !== "text") {
			_setIsElementNearCenter({
				horizontal: false,
				vertical: false,
			});
			return;
		}

		const x = activeElement?.position[0]! + activeElement?.size[0]! / 2;
		const y = activeElement?.position[1]! + activeElement?.size[1]! / 2;

		let horizontal = false;
		let vertical = false;
		if (Math.abs(x - 0.5) < 0.015) {
			horizontal = true;
		}
		if (Math.abs(y - 0.5) < 0.015) {
			vertical = true;
		}
		_setIsElementNearCenter({
			horizontal: horizontal,
			vertical: vertical,
		});
	};

	useEffect(() => {
		setIsElementNearCenter();
	}, [activeElement?.position]);

	return (
		<>
			<div
				className="gigauser-canvasplayer"
				style={{
					display: cp.loading ? "none" : undefined,
				}}
			>
				{videoWidth && videoHeight ? (
					<>
						<canvas
							id="gigauser-video-canvas"
							className="gigauser-video-canvas"
							width={canvasWidth}
							height={canvasHeight}
							ref={canvasRef}
							onMouseMove={handleMouseMove}
							onMouseLeave={() => {
								_setIsElementNearCenter({
									vertical: false,
									horizontal: false,
								});
							}}
							onClick={() => {
								dispatch(setActiveElement(null));
							}}
						></canvas>
						{primeElement && showElementEditor(primeElement) ? (
							<div style={{ backgroundColor: "red" }}>
								<div
									className={`canvas-hoverElementDiv ${activeElement ? "hide-border" : "show-border"} `}
									onClick={(e) => {
										dispatch(setCustomizerPage("Elements"));
										dispatch(setActiveElement(primeElement.id));
										e.stopPropagation();
									}}
									style={{
										left: `${hoverDivTopLeft.x}px`,
										top: `${hoverDivTopLeft.y}px`,
										width: `${hoverDivWidth}px`,
										height: `${hoverDivHeight}px`,
									}}
								>
									{activeElement && showElementEditor(activeElement) ? (
										<DragController
											shape={activeElement}
											zoomFactor={currentZoom?.zoomFactor}
											getRelativeCoords={(pos: Coordinates) =>
												pixelsToFractionalCoords(pos, true)
											}
											updateShape={updateElementEdit}
											toggleGuideLines={_setIsElementNearCenter}
											boundLimits
										/>
									) : null}
									{sizeControls.map((pos) =>
										activeElement && showElementEditor(activeElement) ? (
											<SizeControllerThumb
												shape={activeElement}
												position={pos}
												key={pos}
												zoomFactor={currentZoom?.zoomFactor}
												getRelativeCoords={(pos: Coordinates) =>
													pixelsToFractionalCoords(pos, true)
												}
												updateShape={updateElementEdit}
												boundLimits
												lockRatio={activeElement.geo == "text"}
											/>
										) : null,
									)}
								</div>
								{activeElement &&
								showElementEditor(activeElement) &&
								isElementNearCenter.vertical ? (
									<div className="horizontal-line"></div>
								) : null}

								{activeElement &&
								showElementEditor(activeElement) &&
								isElementNearCenter.horizontal ? (
									<div className="vertical-line"></div>
								) : null}
							</div>
						) : null}
					</>
				) : null}
			</div>

			<div
				className="gigauser-canvasplayer-spinner"
				style={{
					display: cp.loading ? undefined : "none",
				}}
			>
				<Spinner size={"xl"} color="#d43f8c" />
			</div>
		</>
	);
};

export default CanvasPlayer;
