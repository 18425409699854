import {
	AudioTrack,
	BackgroundOption,
	Cover,
	CoverOption,
	CoverType,
	Logo,
	PlatformDetails,
} from "@giga-user-fern/api/types/api";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { GigaUserApi } from "@giga-user-fern/api";

type PlatformDetailsState = {
	value: PlatformDetails | null;
};

const initialState: PlatformDetailsState = {
	value: null,
};

export const platformDetailSlice = createSlice({
	name: "platformDetails",
	initialState,
	reducers: {
		addAudioTrack: (state, action: PayloadAction<AudioTrack>) => {
			if (state.value && state.value.audioTracks)
				state.value.audioTracks.push(action.payload);
		},

		addLogo: (state, action: PayloadAction<Logo>) => {
			if (state.value && state.value.logos)
				state.value.logos.push(action.payload);
		},

		setPlatformDetails: (state, action: PayloadAction<PlatformDetails>) => {
			state.value = action.payload;
		},

		addCover: (
			state,
			action: PayloadAction<{ coverOption: CoverOption; coverType: CoverType }>,
		) => {
			const { coverOption, coverType } = action.payload;
			const list =
				coverType == "intro" ? state.value?.intro : state.value?.outro;

			if (list) {
				//there are already some intro options
				list.options = [...list.options, coverOption];
			} else {
				//there are no intro options
				const new_options = {
					default: coverOption.id,
					options: [coverOption],
				};

				state.value = {
					...state.value,
					intro: coverType == "intro" ? new_options : state.value?.intro,
					outro: coverType == "outro" ? new_options : state.value?.outro,
				};
			}
		},

		deleteCover: (
			state,
			action: PayloadAction<{ coverOption: CoverOption; coverType: CoverType }>,
		) => {
			const { coverOption, coverType } = action.payload;
			const list =
				coverType == "intro" ? state.value?.intro : state.value?.outro;

			if (list && list.options) {
				//there are already some intro options

				if (list.options.length == 1) {
					//don't delete the last option
					return;
				}

				list.options = list.options.filter(
					(option) => option.id !== coverOption.id,
				);
			}
		},

		updateCover: (
			state,
			action: PayloadAction<{ coverOption: CoverOption; coverType: CoverType }>,
		) => {
			const { coverOption, coverType } = action.payload;
			const list =
				coverType == "intro" ? state.value?.intro : state.value?.outro;

			if (list?.options) {
				list.options = list.options.map((option) => {
					if (option.id === coverOption.id) {
						return coverOption;
					}
					return option;
				});
			}
		},

		addBackground: (state, action: PayloadAction<BackgroundOption>) => {
			const backgroundOption = action.payload;
			const list = state.value?.background;

			if (list) {
				//there are already some background options
				list.options = [...list.options, backgroundOption];
			} else {
				//there are no bg options
				const new_options = {
					default: backgroundOption.id,
					options: [backgroundOption],
				};

				state.value = {
					...state.value,
					background: new_options,
				};
			}
		},

		deleteBackground: (state, action: PayloadAction<BackgroundOption>) => {
			const bgOption = action.payload;
			const list = state.value?.background;

			if (list && list.options) {
				//there are already some intro options

				if (list.options.length == 1) {
					//don't delete the last option
					return;
				}

				list.options = list.options.filter(
					(option) => option.id !== bgOption.id,
				);
			}
		},
	},
});

export const {
	addBackground,
	deleteBackground,
	addAudioTrack,
	updateCover,
	setPlatformDetails,
	addLogo,
	addCover,
	deleteCover,
} = platformDetailSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export default platformDetailSlice.reducer;

export const selectAudioTracks = (state: RootState) =>
	state.platformDetails?.value?.audioTracks || [];
export const selectLogos = (state: RootState) =>
	state.platformDetails?.value?.logos || [];
export const selectPlatformDetails = (state: RootState) =>
	state.platformDetails;
