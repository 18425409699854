import { ColorPicker } from "antd";
import React from "react";
import { ravelColor, unravelColor } from "../../../utils/colorUtils";
import { Color } from "antd/es/color-picker";

type CColorPickerProps = {
	allowClear?: boolean;
	disableOpacity?: boolean;
	color: string;
	opacity?: number; //[0,100]
	onChange: (color?: string, opacity?: number) => void;
	children: [React.ReactNode, ...React.ReactNode[]] | React.ReactNode;
};

const CColorPicker: React.FC<CColorPickerProps> = ({
	color = "#000000",
	opacity = 100,
	...props
}) => {
	const fullColor = ravelColor(color, opacity);

	const onChange = (_: Color, hex: string) => {
		const { color, opacity } = unravelColor(hex);

		if (props.allowClear && opacity === 0) {
			props.onChange(undefined);
		} else {
			props.onChange(color, opacity);
		}
	};

	return (
		<ColorPicker
			allowClear={props.allowClear}
			disabledAlpha={props.disableOpacity}
			value={fullColor}
			onChange={onChange}
		>
			{props.children}
		</ColorPicker>
	);
};
export default CColorPicker;
