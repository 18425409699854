//@ts-nocheck
import posthog from "posthog-js";
import { Organization } from "../types/guide";
const min = 1 / 3;
const mid = 2 / 3;
const max = 3 / 3;

enum CsatFeedback {
	min,
	mid,
	max,
}
let loaded = false;
export type GuideRecordingStarted = {
	eventName: "GuideRecordingStarted";
	value: {};
};
export type GuideRecordingCompleted = {
	eventName: "GuideRecordingCompleted";
	value: {};
};
export type GuideCreated = {
	eventName: "GuideCreated";
	value: { guide_id: string };
};

export type GuideUpdated = {
	eventName: "GuideUpdated";
	value: { guide_id: string };
};
export type GuideEnhanced = {
	eventName: "GuideEnhanced";
	value: { guide_id: string | null };
};

export type GuideOpenedInHelpCenter = {
	eventName: "GuideOpenedInHelpCenter";
	value: { guide_id: string };
};
export type NewButtonClicked = {
	eventName: "NewButtonClicked";
	value: {};
};

export type VideoPlayedInHelpCenter = {
	eventName: "VideoPlayedInHelpCenter";
	value: { guide_id: string };
};

export type SearchRunInHelpCenter = {
	eventName: "SearchRunInHelpCenter";
	value: { searchString: string };
};

export type SearchReturnsNoResults = {
	eventName: "SearchReturnsNoResults";
	value: { searchString: string };
};

export type Error = {
	eventName: "UploadError";
	value: { error: string };
};

export type GuideRatedInHelpCenter = {
	eventName: "GuideRatedInHelpCenter";
	value: {
		guide_id: string;
		rating: CsatFeedback;
	};
};

export type GuideFeedbackGivenInHelpCenter = {
	eventName: "GuideFeedbackGivenInHelpCenter";
	value: {
		guide_id: string;
		feedback: string;
	};
};

export type ScreenshotEditedTextGuide = {
	eventName: "ScreenshotEditedTextGuide";
	value: {
		guide_id: string;
	};
};

export type GifEditedTextGuide = {
	eventName: "GifEditedTextGuide";
	value: {
		guide_id: string;
	};
};

export type ScreenshotAddedInArticleFromVideo = {
	eventName: "ScreenshotAddedInArticleFromVideo";
	value: {
		guide_id: string;
	};
};

export type ScreenshotAddedInTranscriptFromVideo = {
	eventName: "ScreenshotAddedInTranscriptFromVideo";
	value: {
		guide_id: string;
	};
};

export type VideoChunkFailedToUpload = {
	eventName: "VideoChunkFailedToUpload";
	value: {
		guide_id: string;
	};
};

export type CropVideo = {
	eventName: "CropVideo";
	value: {
		guide_id: string;
	};
};

export type AddTextboxEvent = {
	eventName: "TextboxAdded";
	value: {
		guide_id: string;
	};
};

export type AnalyticsEvent =
	| GuideOpenedInHelpCenter
	| Error
	| GuideRecordingStarted
	| GuideRecordingCompleted
	| GuideCreated
	| GuideUpdated
	| NewButtonClicked
	| GuideEnhanced
	| SearchRunInHelpCenter
	| SearchReturnsNoResults
	| VideoPlayedInHelpCenter
	| GuideRatedInHelpCenter
	| GuideFeedbackGivenInHelpCenter
	| ScreenshotEditedTextGuide
	| ScreenshotAddedInArticleFromVideo
	| ScreenshotAddedInTranscriptFromVideo
	| VideoChunkFailedToUpload
	| GifEditedTextGuide
	| CropVideo
	| AddTextboxEvent;

export class AnalyticsLogger {
	/**
	 * This class is used for platform.
	 * The standalone functions outside this class are used for the other products.
	 * TODO: Migrate the other products to also use this class later.
	 */

	organization?: Organization;
	email?: string;

	constructor(org?: Organization, email?: string) {
		this.organization = org;
		this.email = email;

		console.log("initializeAnalytics Posthog: ", org);
		try {
			posthog.init("phc_K9xtjpstJIE86BCqAq7SLsbsWK31H7YggUQZIf8xf9x", {
				api_host: "https://app.posthog.com",
				loaded: function (posthog) {
					// posthog.group('company', org.id, {name: org.name})
				},
				autocapture: false,
				capture_pageview: false,
				capture_pageleave: false,
			});

			posthog.group("company", org.id);
			// Identify group once on sign in instead
		} catch (e) {
			try {
				logger.error(e);
				posthog.capture("Error", e);
			} catch (e) {}
		}
	}

	setGuideId = (guideId: string) => {
		this.guideId = guideId;
	};

	captureEvent = (event: AnalyticsEvent) => {
		if (
			process.env.NODE_ENV === "development" ||
			window.location.href.includes("localhost")
		)
			return;

		if (!this.email || !this.organization) {
			return;
		}

		console.log("Capturing", event);
		try {
			posthog.capture(event.eventName, {
				...event.value,
				$set: {
					email: this.email,
					company: this.organization?.id.toString(),
				},
			});
		} catch (e) {}
	};
}

export function initializeAnalytics(org: Organization) {
	console.log("initializeAnalytics Posthog: ", org);
	try {
		posthog.init("phc_K9xtjpstJIE86BCqAq7SLsbsWK31H7YggUQZIf8xf9x", {
			api_host: "https://app.posthog.com",
			loaded: function (posthog) {
				// posthog.group('company', org.id, {name: org.name})
			},
			autocapture: false,
			capture_pageview: false,
			capture_pageleave: false,
		});

		posthog.group("company", org.id);
		// Identify group once on sign in instead
	} catch (e) {
		try {
			logger.error(e);
			posthog.capture("Error", e);
		} catch (e) {}
	}
}

export function groupName(org: Organization) {
	try {
		posthog.group("company", org.id, { name: org.name });
	} catch (e) {}
}

export function captureEvent(event: AnalyticsEvent) {
	console.log("Capturing", event);
	try {
		posthog.capture(event.eventName, {
			...event.value,
		});
	} catch (e) {}
}

export function loadPostHogDynamically(apiKey, instanceAddress, orgId) {
	// Create a new script element
	if (loaded) {
		return;
	}
	loaded = true;
	const loadPScript = (apiKey, instanceAddress) => {
		const scriptElem = document.createElement("script");

		// Set the content of the script element
		scriptElem.innerHTML = `
    !function(t,e){var o,n,p,r;e.__SV||(window.posthog=e,e._i=[],e.init=function(i,s,a){function g(t,e){var o=e.split(".");2==o.length&&(t=t[o[0]],e=o[1]),t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}}(p=t.createElement("script")).type="text/javascript",p.async=!0,p.src=s.api_host+"/static/array.js",(r=t.getElementsByTagName("script")[0]).parentNode.insertBefore(p,r);var u=e;for(void 0!==a?u=e[a]=[]:a="posthog",u.people=u.people||[],u.toString=function(t){var e="posthog";return"posthog"!==a&&(e+="."+a),t||(e+=" (stub)"),e},u.people.toString=function(){return u.toString(1)+".people (stub)"},o="capture identify alias people.set people.set_once set_config register register_once unregister opt_out_capturing has_opted_out_capturing opt_in_capturing reset isFeatureEnabled onFeatureFlags getFeatureFlag getFeatureFlagPayload reloadFeatureFlags group updateEarlyAccessFeatureEnrollment getEarlyAccessFeatures getActiveMatchingSurveys getSurveys".split(" "),n=0;n<o.length;n++)g(u,o[n]);e._i.push([i,s,a])},e.__SV=1)}(document,window.posthog||[]);
    posthog.init('${apiKey}', {api_host: '${instanceAddress}', autocapture: false,
    capture_pageview: false,
    capture_pageleave: false});
    `;

		// Append the script to the body or head
		document.body.appendChild(scriptElem);
	};
	if (
		!window.location.host.includes("127.0.0.1") &&
		!window.location.host.includes("localhost")
	) {
		loadPScript(apiKey, instanceAddress);
		posthog.init(apiKey, {
			api_host: instanceAddress,
			autocapture: false,
			capture_pageview: false,
			capture_pageleave: false,
		});
		// Try for user
		const user = localStorage.getItem("clueso-helpcenter-user");
		if (user) {
			posthog.identify(user);
		}
	}
}

// Call the function with your API key and instance address
