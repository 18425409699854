import React, { useEffect, useRef, useState } from "react";
import "./RichTextToolbar.css";
import downArrowheadIcon from "../../../../../assets/svgs/downArrowheadIcon";

import magicwandIcon from "../../../../../assets/svgs/magicwandIcon";
import aiRewriteIcon from "../../../../../assets/svgs/aiRewriteIcon";
import generateSpeechIcon from "../../../../../assets/svgs/generateSpeechIcon";
import Icon from "../../../../../ui/Icon/Icon";
import undoIcon from "../../../../../assets/svgs/undoIcon";
import redoIcon from "../../../../../assets/svgs/redoIcon";
import {
	useActive,
	useChainedCommands,
	useCommands,
	useHelpers,
	useRemirrorContext,
} from "@remirror/react";
import { PlainDoc } from "@giga-user-fern/api/types/api/resources/guides";
import { cleanJSON, refillImages } from "../../utils/cleanImageSrc";
import LoadingRing from "../../../../../assets/gifs/LoadingRing/LoadingRing";
import { RemirrorJSON } from "remirror";
import logger from "../../../../../utils/logger";
import { TextEditorType } from "../../TextEditor";
import voiceIcon from "../../../../../assets/svgs/voiceIcon";
import { eventEmitter } from "../../../../../utils/events";
import { Voice } from "../../../../../core/types/guide";
import BlockTypeDropdown from "./BlockTypeDropdown/BlockTypeDropdown";
import ListOptions from "./ListOptions/ListOptions";
import BlockquoteButton from "./BlockquoteButton/BlockquoteButton";
import HRButton from "./HRButton/HRButton";
import TextStyleOptions from "./TextStyleOptions/TextStyleOptions";
import CodeTypeDropdown from "./CodeTypeDropdown/CodeTypeDropdown";
import { LinkExtension } from "remirror/dist-types/extensions";
import { useEffectOnce } from "../../../../../hooks/useEffectOnce";
import Button from "../../../../../ui/buttons/Button/Button";
import refreshIcon from "../../../../../assets/svgs/refreshIcon";
import { Tooltip } from "@chakra-ui/react";
import useAddScreenshot from "../AddScreenshot/useAddScreenshot";

import clockIcon from "../../../../../assets/svgs/clockIcon";
import imagePlus from "../../../../../assets/svgs/imagePlus";
import airplaneIcon from "../../../../../assets/svgs/airplaneIcon";

import ScreenshotDropdown from "./ScreenshotDropdown/ScreenshotDropdown";
import { getFlag } from "../../../../../utils/voices/voiceUtils";
import HoverTip from "../../../../../ui/HoverTip/HoverTip";

import { useToast } from "@chakra-ui/react";

type RichTextToolbarProps = {
	onEnhance?: () => void;
	onGenerate?: () => void;
	save?: (x: RemirrorJSON) => void;
	version: string;
	scrollContainerRef?: React.MutableRefObject<HTMLDivElement | null>;
	fixToolbar?: boolean;
	textEditorType: TextEditorType;
	enhanceLoading?: boolean;
	generateLoading?: boolean;
	disabled?: boolean;
	process?: "enhancing" | "generating";
};

export type VoiceProps = {
	voice: Voice;
	onClickVoice: () => void;
};

const RichTextToolbar: React.FC<RichTextToolbarProps & (VoiceProps | {})> = (
	props,
) => {
	const mediumGrey = "#4b4b4b";
	const { undo } = useCommands();
	const { redo } = useCommands();
	const { setContent, manager } = useRemirrorContext();
	const { getMarkdown, getHTML } = useHelpers();
	// const dispatch = useAppDispatch()
	const addScreenshot = useAddScreenshot("videoTranscript");
	// This is being used only for transcript. Refer to ScreenshoitDropdown for the article one
	const iconColor =
		props.textEditorType === "videoTranscript" ||
		props.textEditorType === "platform"
			? "#bfc7d4"
			: mediumGrey;

	const toolbarElementRef = useRef<HTMLDivElement | null>(null);
	const [maintenanceWidth, setMaintenanceWidth] = useState<number>(350);

	const [isTop, setIsTop] = useState(false);

	var displayVoice;
	var displayFlag;
	if ("voice" in props) {
		displayVoice = props.voice;
		displayFlag = getFlag(props.voice.languageCountryId || "en-US");
	} else {
		displayFlag = getFlag("en-US");
	}
	const toast = useToast()
	const enhanceDocument = async () => {
		if(!props.disabled){
			await props.onEnhance?.();
		}
		if(props.disabled){
			toast({
				title: `Action not allowed!`,
				description: "The guide you are trying to edit is deleted/disabled, restore to perform actions on it!",
				status: "error",
				duration: 6000,
				isClosable: true,
				position: "top",
			})
		}
	};

	useEffect(() => {
		const useHTML = (callback: (x: string, y: string) => void) => {
			// Simulate fetching or computing some data
			if (props.textEditorType === "platform") {
				const html = getHTML(manager.view.state);
				const markdown = getMarkdown(manager.view.state);
				callback(html, markdown);
			}
		};

		eventEmitter.on("getHTML", useHTML);

		return () => {
			eventEmitter.off("getHTML", useHTML);
		};
	}, [getHTML, manager.view.state, props.textEditorType]);
	if (props.textEditorType === "videoTranscript")
		return (
			<>
				<div
					id="gigauser-RichTextToolbar"
					ref={toolbarElementRef}
					className={`gigauser-RichTextToolbar gigauser-fix-toolbar ${
						isTop ? "gigauser-isTop" : "gigauser-notIsTop"
					}
            transcriptToolbar
            `}
				>
					<div className="ToolbarRow1">
						<HoverTip
							className="aiRewrite-container"
							hoverTipTitle={"AI Rewrite"}
							hoverTipPara="Rephrases your script while keeping content intact. Removes filler words and creates a concise, engaging script for your video."
						>
							<TranscriptToolbarButton
								className="aiRewriteButton"
								text={props.enhanceLoading ? "Writing..." : "AI Rewrite"}
								loading={props.enhanceLoading}
								disabled={props.disabled}
								icon={aiRewriteIcon("#bfc7d4")}
								onClick={enhanceDocument}
							/>
						</HoverTip>
						
						

						{"voice" in props ? (
							<HoverTip
								className="voice-container"
								hoverTipTitle={"Voice actor"}
								hoverTipPara="Choose an AI voice actor for your video from a number of accents."
							>
								<TranscriptToolbarButton
									className="voiceButton"
									text={displayVoice?.name}
									// icon={voiceIcon("#bfc7d4")}
									onClick={!props.disabled?props.onClickVoice:()=>{toast({
										title: `Action not allowed!`,
										description: "The guide you are trying to edit is deleted, restore to perform actions on it!",
										status: "error",
										duration: 6000,
										isClosable: true,
										position: "top",
									})}}
									icon={displayFlag.flag}
									// dropdown
								/>
							</HoverTip>
						) : null}

						{/* <div className="boxIcon-container voiceIcon" onClick={props.onClickVoice}>
                    <Icon className="gigauser-RichTextToolbar-voice-icon" >{voiceIcon("#FE78BD")}</Icon>

                    <span className="gigauser-voice-name">{displayVoice?.name}</span>
                </div> */}

						<HoverTip
							hoverTipTitle={"Add Sync Marker"}
							hoverTipPara="Enforce sync between the voiceover and video by adding frames to your script."
						>
							<TranscriptToolbarButton
								icon={imagePlus("white")}
								onClick={addScreenshot}
								disabled={props.disabled}
								// rank="minor"
								// hoverTip="Add Sync Marker"
							/>
						</HoverTip>

						{ <HoverTip
							className="genspeech-container"
							hoverTipTitle={"Generate Speech"}
							hoverTipPara="Click here to generate the new voiceover for your video from the script."
						>
							<div
								className={`GenerateSpeechButton ${props.disabled ? "disabled" : "enabled"}`}
								onClick={props.disabled ? undefined : props.onGenerate}
							>
								<Icon className="generateSpeechIcon">
									{props.generateLoading ? (
										<LoadingRing></LoadingRing>
									) : (
										generateSpeechIcon("white")
									)}
								</Icon>
								<div>
									{" "}
									{props.generateLoading
										? "Generating ..."
										: "Generate Speech"}{" "}
								</div>
							</div>
						</HoverTip>}
					</div>
				</div>

				<div className="ToolbarRow2">
					{/* <TranscriptToolbarButton
                    icon={imagePlus("white")}
                    rank="minor"
                    hoverTip="Add Sync Marker"
                /> */}

					<TranscriptToolbarButton
						icon={undoIcon("white")}
						rank="minor"
						hoverTip="Undo"
						onClick={undo}
					/>

					<TranscriptToolbarButton
						icon={redoIcon("white")}
						rank="minor"
						hoverTip="Redo"
						onClick={redo}
					/>
				</div>

				{/* <div className="GenerateCircle">
            <Icon className="GenerateIcon">{airplaneIcon("white")}</Icon>

        </div> */}
			</>
		);
	else
		return (
			<div
				id="gigauser-RichTextToolbar"
				ref={toolbarElementRef}
				className={`gigauser-RichTextToolbar gigauser-fix-toolbar ${
					isTop ? "gigauser-isTop" : "gigauser-notIsTop"
				}
            gigauser-dark
            ${props.textEditorType === "platform" ? "gigauser-dark fullWidthToolbar" : ""}
            `}
			>
				<div className="gigauser-toolbar-special-buttons">
					{props.onEnhance ? (
						<HoverTip
							hoverTipTitle={"AI Rewrite"}
							hoverTipPara="Rewrites and formats your article into steps per professional technical writing guidelines."
						>
							<div
								className="gigauser-special-button gigauser-enhance-button"
								onClick={props.enhanceLoading ? undefined : enhanceDocument}
							>
								<Icon className="gigauser-special-button-icon ">
									{props.enhanceLoading ? (
										<LoadingRing color="#f1e2b8" />
									) : (
										magicwandIcon("#f1e2b8")
									)}
								</Icon>
								<div className="gigauser-special-button-text">
									AI Rewrite
									{/* {props.textEditorType==="videoTranscript"?"(BETA)":null} */}
								</div>
							</div>
						</HoverTip>
					) : null}

					{"voice" in props ? (
						<div
							className="boxIcon-container voiceIcon"
							onClick={props.onClickVoice}
						>
							<Icon className="gigauser-RichTextToolbar-voice-icon">
								{voiceIcon("#FE78BD")}
							</Icon>

							<span className="gigauser-voice-name">{displayVoice?.name}</span>
						</div>
					) : null}

					{props.textEditorType === "platform" && (
						<>
							<BlockTypeDropdown disabled={props.disabled}></BlockTypeDropdown>
							<TextStyleOptions disabled={props.disabled}></TextStyleOptions>
							<CodeTypeDropdown disabled={props.disabled}></CodeTypeDropdown>
						</>
					)}
				</div>

				<div className="gigauser-toolbar-regular-buttons">
					<Icon
						disabled={props.disabled}
						className={`gigauser-RichTextToolbar-reg-icon ${props.disabled ? "icon-disabled" : "icon-nondisabled"}`}
						hoverTip="Undo"
						onClick={undo}
					>
						{undoIcon(iconColor)}
					</Icon>

					<Icon
						disabled={props.disabled}
						className={`gigauser-RichTextToolbar-reg-icon ${props.disabled ? "icon-disabled" : "icon-nondisabled"}`}
						hoverTip="Redo"
						onClick={redo}
					>
						{redoIcon(iconColor)}
					</Icon>
				</div>
			</div>
		);
};

type TranscriptButtonProps = {
	icon: JSX.Element;
	text?: string;
	className?: string;
	dropdown?: boolean;
	onClick?: () => void;

	loading?: boolean;
	rank?: "major" | "minor";
	hoverTip?: string;
	disabled?: boolean;
};

const TranscriptToolbarButton: React.FC<TranscriptButtonProps> = (props) => {
	return (
		<div
			onClick={props.loading || props.disabled ? undefined : props.onClick}
			className={`TranscriptToolbarButton ${props.className} ${props.rank} ${props.disabled ? "disabled" : "enabled"} `}
		>
			{props.loading ? (
				<div className="transbuttonloading">
					<LoadingRing color="hsl(224, 13%, 57%)" />
				</div>
			) : (
				<Icon
					hoverTip={props.hoverTip}
					hoverPosition={props.rank == "minor" ? "top" : "bottom"}
					className={`transcriptToolbarIcon ${props.text ? "yestext" : "notext"}`}
				>
					{props.icon}
				</Icon>
			)}
			{props.text ? (
				<div className="TranscriptToolbarButtonText"> {props.text}</div>
			) : null}
			{props.dropdown ? (
				<Icon className="trans-down-icon">{downArrowheadIcon("#bfc7d4")}</Icon>
			) : null}
		</div>
	);
};

export default RichTextToolbar;
