import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { GigaUserApi } from "@giga-user-fern/api";
import { Speed, defaultSpeed } from "../../utils/speedUtils";

export type PlatformHomePage =
	| "home"
	| "bin"
	| "organization"
	| "anal"
	| "admin"
	| "hosting"
	| "help-center-config"
	| "settings"
	| "usage";

export type VideoCustomizerPage =
	| "Intro"
	| "Background"
	| "Outro"
	| "Zooms"
	| "Elements"
	| "Music"
	| "Voiceover";
export type ActiveFormat = "Video" | "Article";

type PlatformUiState = {
	value: {
		homePage: PlatformHomePage;
		activeFormat: ActiveFormat;
		videoUploadProgress: number | null;
		customizerPage: VideoCustomizerPage | null;
		activeZoom: GigaUserApi.Id | null;
		activeElement: GigaUserApi.Id | null;
		hardRefreshTranscript: boolean;
		exportSrtFile: boolean;

		activeIntroTemplate: GigaUserApi.Id | null;
		activeOutroTemplate: GigaUserApi.Id | null;
		activeBackgroundTemplate: GigaUserApi.Id | null;
	};
};

const initialState: PlatformUiState = {
	value: {
		homePage: "home",
		activeFormat: "Video",
		videoUploadProgress: null,
		customizerPage: "Voiceover",
		activeZoom: null,
		activeElement: null,
		hardRefreshTranscript: false,
		exportSrtFile: false,
		activeIntroTemplate: GigaUserApi.Id(""),
		activeOutroTemplate: GigaUserApi.Id(""),
		activeBackgroundTemplate: GigaUserApi.Id(""),
	},
};

export const platformUiSlice = createSlice({
	name: "platformUi",
	initialState: initialState,
	reducers: {
		setHomePage: (state, action: PayloadAction<PlatformHomePage>) => {
			state.value.homePage = action.payload;
		},

		setActiveFormat: (state, action: PayloadAction<ActiveFormat>) => {
			state.value.activeFormat = action.payload;
		},

		setCustomizerPage: (
			state,
			action: PayloadAction<VideoCustomizerPage | null>,
		) => {
			state.value.customizerPage = action.payload;
		},

		setActiveZoom: (state, action: PayloadAction<GigaUserApi.Id | null>) => {
			state.value.activeZoom = action.payload;
		},

		setActiveElement: (state, action: PayloadAction<GigaUserApi.Id | null>) => {
			state.value.activeElement = action.payload;
		},

		setHardRefreshTranscript: (state, action: PayloadAction<boolean>) => {
			state.value.hardRefreshTranscript = action.payload;
		},

		setExportSrtFile: (state, action: PayloadAction<boolean>) => {
			state.value.exportSrtFile = action.payload;
		},

		setVideoUploadProgress: (state, action: PayloadAction<number>) => {
			state.value.videoUploadProgress = action.payload;
		},

		setActiveIntroTemplate: (
			state,
			action: PayloadAction<GigaUserApi.Id | null>,
		) => {
			state.value.activeIntroTemplate = action.payload || GigaUserApi.Id("");
		},

		setActiveOutroTemplate: (
			state,
			action: PayloadAction<GigaUserApi.Id | null>,
		) => {
			state.value.activeOutroTemplate = action.payload || GigaUserApi.Id("");
		},

		setActiveBackgroundTemplate: (
			state,
			action: PayloadAction<GigaUserApi.Id | null>,
		) => {
			state.value.activeBackgroundTemplate =
				action.payload || GigaUserApi.Id("");
		},
	},
});

export const {
	setActiveBackgroundTemplate,
	setActiveIntroTemplate,
	setActiveOutroTemplate,
	setVideoUploadProgress,
	setExportSrtFile,
	setActiveZoom,
	setActiveElement,
	setHardRefreshTranscript,
	setHomePage,
	setCustomizerPage,
	setActiveFormat,
} = platformUiSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export default platformUiSlice.reducer;
