import { useContext } from "react";
import { Tree } from "../../../../redux/slices/hcSlice";
import ExtHelpCenterContext from "../../ExtHelpCenterContext";

const useGetSubtree: () => (collectionId?: string) => Promise<Tree> = () => {
	const helpCenterContext = useContext(ExtHelpCenterContext);

	return async (collectionId?: string) => {
		const res = await helpCenterContext.fetchAllChildren(collectionId);
		const { collections, guidePreviews } = res;

		console.log("res: ", res);

		var tree: Tree = [];

		guidePreviews.map((guidePreview) => {
			tree.push({
				type: "guide",
				guide: guidePreview,
			});
		});

		collections.map(async (collection) => {
			if (false) {
				const subTreeRes = await helpCenterContext.fetchAllChildren(
					collection.id,
				);

				const subCollections = subTreeRes.collections;
				const subGuides = subTreeRes.guidePreviews;

				var subTree: Tree = [];

				subGuides.map((guidePreview) => {
					subTree.push({
						type: "guide",
						guide: guidePreview,
					});
				});

				console.log("subTree: ", subTree);

				subCollections.map((c) => {
					console.log("pushing: ", c);
					subTree.push({
						type: "collection",
						collection: c,
						collapsed: true,
					});
				});
			}

			tree.push({
				type: "collection",
				collection: collection,
				collapsed: true,
			});
		});

		return tree;
	};
};

export default useGetSubtree;
